import React, { useContext, useRef, useEffect } from "react";
import "./work-section.scss";
import gsap from "gsap";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "../../links";
import ReactContext from "../../../context/reactContext";
import classNames from "classnames";
import { projects } from "../../../data/pages/work";

const WorkSection = ({ workLogoImages }) => {
  const { theme, isDesktop, setCurrentWorkImage, activeSection } =
    useContext(ReactContext);
  const workContainerRef = useRef(null);
  const projectsRef = useRef(null);
  const workProjectClasses = classNames("work-section__project", {
    "work-section__project--non-desktop": !isDesktop,
  });

  useEffect(() => {
    const allProjects = [...projectsRef.current.children];
    allProjects.forEach((project, index) => {
      if (activeSection === "db-work") {
        gsap.to(project, {
          opacity: 1,
          duration: 0.15,
          delay: index / 12,
          y: 0,
        });
      } else {
        gsap.to(project, {
          y: 50,
          opacity: 0,
          delay: index / 12,
          duration: 0.15,
        });
      }
    });
  }, [activeSection]);

  return (
    <div ref={workContainerRef} className="work-section">
      <div className="work-section__inner">
        <div className="work-section__heading">
          <h2>My work</h2>
        </div>
        <div ref={projectsRef} className="work-section__index">
          {projects.map((project) => {
            const {
              liveLink,
              github,
              myRole,
              techStack,
              logo,
              logoDark,
              id,
              title,
              projectName,
              image,
              pageLink,
            } = project;
            const whichImage = theme && logoDark ? logoDark : logo;
            return (
              <Link
                onMouseOver={() => setCurrentWorkImage(image)}
                key={id}
                className={`${workProjectClasses} cursor-pointer`}
                to={pageLink}
              >
                <GatsbyImage
                  alt={logo}
                  width={300}
                  image={workLogoImages[whichImage]}
                />
                <p className="work-section__project__title cursor-pointer">
                  {title}
                </p>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WorkSection;
