import React from "react";
import classNames from "classnames";
import "./ts-cursor.scss";

const TSCustomCursor = React.forwardRef((props, ref) => {
  const cursorClasses = classNames("db-custom-cursor", {
    "db-custom-cursor--active": props.active,
  });
  return (
    <>
      {props.active && <div className={cursorClasses} ref={ref}>
        {props.component}
      </div>}
    </>
  );
});

export default TSCustomCursor;
