import React, { useState, useRef, useEffect } from "react";
import gsap from "gsap";
import "./help-card.scss";

const HelpCard = ({ data, index, color, activeSection }) => {
  const { title, text, icon } = data;
  const cardRef = useRef(null);

  useEffect(() => {
    if (activeSection === "db-help") {
      gsap.to(cardRef.current, {y: 0, duration: 0.3, opacity: 1, delay: 0.1 + (index / 16)})
    } 
    if (activeSection !== "db-help"){
      gsap.to(cardRef.current, {
        y: 100,
        duration: 0.3,
        opacity: 0.1,
        delay: index / 16,
      });
    }
  },[activeSection]);

  return (
    <div ref={cardRef} className="db-help-card">
      <h6>0{index + 1}</h6>
      <div
        style={{ backgroundColor: color }}
        className="db-help-card__icon-wrapper"
      >
        {icon}
      </div>
      <h4>{title}</h4>
      <p>{text}</p>
    </div>
  );
};

export default HelpCard;
