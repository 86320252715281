import * as React from "react";
import { useContext, useRef, useEffect } from "react";
import "../styles/pages/homepage.scss";
import { graphql } from "gatsby";
import gsap from "gsap";
import { assetArrayToObject } from "../lib/graphql-helpers";
import { TechStack, Testimonials, WorkSection } from "../components/homepage";
import {
  allTechStack,
  values,
  help,
  colors,
  subHeader,
} from "../data/pages/homepage";
import HelpCard from "../components/homepage/help";
import CTA from "../components/cta";
import EntryPage from "../components/EntryPage";
import useSectionHighlight from "../lib/useSectionHighlight";
import classNames from "classnames";
import ReactContext from "../context/reactContext";

export const Home = ({ data }) => {
  const {
    icons: { edges: iconImages },
    workImages: { edges: workImages },
    workLogoImages: { edges: workLogoImages },
  } = data;

  const homepageRef = useRef(null);
  const periodsRef = useRef(null);
  const subHeaderRef = useRef(null);

  const workIconImages = assetArrayToObject({ images: iconImages });
  const workPageImages = assetArrayToObject({
    useGatsbyImage: true,
    images: workImages,
  });
  const workLogoPageImages = assetArrayToObject({
    useGatsbyImage: true,
    images: workLogoImages,
  });
  const { whichIntro, activeSection } = useContext(ReactContext);

  useEffect(() => {
    if (activeSection === "db-help") {
      Array.from(periodsRef.current.children).forEach((period, index) => {
        index = index + 1;
        gsap.fromTo(
          period,
          { opacity: 0, delay: index / 8 },
          {
            opacity: 1,
            duration: 0.3,
            delay: index / 8,
          }
        );
      });
    }

    if (activeSection === "db-introduction") {
      console.log("HITTING");
      const subHeaderWords = [...subHeaderRef.current.children];
      subHeaderWords.forEach((word, index) => {
        gsap.fromTo(
          word.children[0],
          {
            y: 40,
            duration: 0.5,
            delay: index * 0.015,
          },
          {
            y: 0,
            duration: 0.5,
            delay: index * 0.015,
          }
        );
      });
    }
  }, [activeSection]);

  useSectionHighlight(homepageRef);

  const introClasses = classNames("db-homepage__introduction", {
    "db-homepage__introduction--active": activeSection == "db-introduction",
  });
  const helpClasses = classNames("db-homepage__help", {
    "db-homepage__help--active": activeSection == "db-help",
  });
  const workClasses = classNames("db-homepage__work", {
    "db-homepage__work--active": activeSection == "db-work",
  });
  const techClasses = classNames("db-homepage__tech-stack", {
    "db-homepage__tech-stack--active": activeSection == "db-tech-stack",
  });
  const testimonialsClasses = classNames("db-homepage__testimonials", {
    "db-homepage__testimonials--active": activeSection == "db-testimonials",
  });

  return (
    <>
      {/* <EntryPage text="hello!" /> */}
      <div ref={homepageRef} className="db-homepage">
        <section id="db-introduction" className={introClasses}>
          <div className="db-homepage__introduction__inner">
            <div className="db-homepage__introduction__header">
              <h1>Freelance Web Developer</h1>
              <h1>Senior Frontend Engineer</h1>
            </div>
            <div className="db-homepage__introduction__text">
              <div className="db-homepage__introduction__text__subheader">
                <h4 ref={subHeaderRef}>
                  {subHeader.split(" ").map((word) => (
                    <span key={word}>
                      <span>{`${word} `}</span>{" "}
                    </span>
                  ))}
                </h4>
              </div>
            </div>
            <div className="db-homepage__introduction__content">
              {whichIntro === "Introduction" ? (
                <>
                  <div className="db-homepage__introduction__content__ctas">
                    <CTA isAnchor black={true} glow={true} to="#db-work">
                      View my work
                    </CTA>
                    <CTA to="/contact">Contact me</CTA>
                    <CTA to="/blog">Contentful blog</CTA>
                    <CTA to="/about">More about me</CTA>
                  </div>
                </>
              ) : (
                <ul>
                  {values.map((item) => {
                    return <li key={item}>{item}</li>;
                  })}
                </ul>
              )}
            </div>
          </div>
        </section>
        <section id="db-work" className={workClasses}>
          <WorkSection workLogoImages={workLogoPageImages} />
        </section>
        <section id="db-help" className={helpClasses}>
          <div className="db-homepage__help__inner">
            <h2>
              How I can help
              <div ref={periodsRef}>
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </div>
            </h2>
            <p>
              • From filmmakers to tech startups, I've channeled my passion for
              designing and building to help teams elevate their digital
              marketing to the next level.
            </p>
            <div className="db-homepage__help__content">
              {help.map((card, index) => {
                const color = colors[index];
                return (
                  <HelpCard
                    key={card.title}
                    activeSection={activeSection}
                    color={color}
                    index={index}
                    data={card}
                  />
                );
              })}
            </div>
          </div>
        </section>
        <section id="db-tech-stack" className={techClasses}>
          <TechStack iconImages={workIconImages} techStack={allTechStack} />
        </section>
        <section id="db-testimonials" className={testimonialsClasses}>
          <Testimonials />
        </section>
      </div>
    </>
  );
};

export const query = graphql`
  query {
    icons: allFile(
      filter: { extension: { eq: "svg" }, relativeDirectory: { eq: "icons" } }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
            fluid {
              src
              srcSet
              sizes
            }
          }
        }
      }
    }

    workImages: allFile(
      filter: {
        extension: { eq: "png" }
        relativeDirectory: { eq: "work/display" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(width: 1000, placeholder: BLURRED)
            fluid {
              src
              srcSet
              sizes
            }
          }
        }
      }
    }

    workLogoImages: allFile(
      filter: {
        extension: { eq: "png" }
        relativeDirectory: { eq: "work/logos" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(width: 480, placeholder: BLURRED)
            fluid {
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`;

export default React.memo(Home);
