// Filename: ToggleSwitch.js
import React, { useContext } from "react";
import "./toggle-switch.scss";
import classNames from "classnames";
import ReactContext from "../../context/reactContext";
import useSound from "use-sound";
import sound from "../../../static/button.mp3";

const ToggleSwitch = ({ label, toggleSwitch, setToggleSwitch }) => {
  const { hasSound } = useContext(ReactContext);

  const [playActive] = useSound(sound, {
    volume: 0.25,
  });

  const handleToggle = () => {
    if (hasSound) {
      playActive();
    }
    setToggleSwitch((prevToggle) => !prevToggle);
  };

  const sliderClasses = classNames("toggle-switch cursor-pointer", {
    "toggle-switch--checked cursor-pointer": toggleSwitch,
  });

  return (
    <label aria-label="toggle-switch" className={sliderClasses}>
      <input onClick={() => handleToggle()} type="checkbox" />
      <span className="toggle-switch__button" />
      <svg
        className="toggle-switch__icon"
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 256 256"
      >
        <path
          fill="var(--primary)"
          d="M100 36H56a20 20 0 0 0-20 20v44a20 20 0 0 0 20 20h44a20 20 0 0 0 20-20V56a20 20 0 0 0-20-20Zm-4 60H60V60h36Zm104-60h-44a20 20 0 0 0-20 20v44a20 20 0 0 0 20 20h44a20 20 0 0 0 20-20V56a20 20 0 0 0-20-20Zm-4 60h-36V60h36Zm-96 40H56a20 20 0 0 0-20 20v44a20 20 0 0 0 20 20h44a20 20 0 0 0 20-20v-44a20 20 0 0 0-20-20Zm-4 60H60v-36h36Zm104-60h-44a20 20 0 0 0-20 20v44a20 20 0 0 0 20 20h44a20 20 0 0 0 20-20v-44a20 20 0 0 0-20-20Zm-4 60h-36v-36h36Z"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 48 48"
        className="toggle-switch__text"
      >
        <g
          fill="none"
          stroke="var(--bg)"
          strokeLinejoin="round"
          strokeWidth="4"
        >
          <rect width="36" height="36" x="6" y="6" rx="3" />
          <path strokeLinecap="round" d="M16 19v-3h16v3M22 34h4m-2-16v16" />
        </g>
      </svg>
    </label>
  );
};

export default ToggleSwitch;
