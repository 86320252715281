import React, { useContext, useState } from "react";
import ReactContext from "../../context/reactContext";
import classNames from "classnames";
import "./modal.scss";

const useModal = () => {
  const { setModalComponent } = useContext(ReactContext);
  

  const closeModal = () => {
    setModalComponent(null);
  };

  
  const openModal = (ModalComponent, background=false) => {
    const innerClasses = classNames("modal-container__inner cursor-pointer", {
      "modal-container__inner--background": background,
    });
    setModalComponent(() => {
      return (
        <div
          className="modal-container cursor-pointer"
          onClick={() => closeModal()}
        >
          <div className={innerClasses}>{ModalComponent}</div>
        </div>
      );
    });
  };


  return { openModal, closeModal };
};

export default useModal;
