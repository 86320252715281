import React from "react";
import "./testimonials.scss";
import TestimonialCard from "./testimonial-card";
import { testimonials } from "../../../data/pages/homepage";

const Testimonials = () => {
  return (
    <div className="testimonials">
      <h2>Testimonials</h2>
      <div className="testimonials__index">
        {testimonials.map((testimonial, index) => {
          return (
            <TestimonialCard
              index={index}
              testimonial={testimonial}
              key={testimonial.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Testimonials;
