import React, { useContext, useEffect, useRef } from "react";
import gsap from "gsap";
import "./testimonial-card.scss";
import classNames from "classnames";
import { Link } from "../../links";
import { useCheckScreenSize } from "../../../lib/checkScreenSize";
import ReactContext from "../../../context/reactContext";

const TestimonialCard = ({ testimonial }) => {
  const {
    name,
    quote,
    company,
    role,
    linkedin = false,
    personalSite = false,
    index,
    danielQuote,
  } = testimonial;
  const { currentQuote, setCurrentQuote, activeSection } = useContext(ReactContext);
  const personalLink = personalSite ? personalSite : linkedin;
  const cardTestRef = useRef(null);

  useEffect(() => {
    // gsap.set(cardTestRef.current, { y: 70, opacity: 0.1 });
    if (activeSection === "db-testimonials") {
      gsap.to(cardTestRef.current, {
        y: 0,
        duration: 0.3,
        opacity: 1,
        delay: index / 22,
      });
    } else {
      gsap.to(cardTestRef.current, {
        y: 70,
        duration: 0.3,
        opacity: 0.1,
        delay: index / 22,
      });
    }
  }, [activeSection]);

  const cardClasses = classNames("testimonial-card", {
    "testimonial-card--FFB2CE--active":
      (index === 0) & (danielQuote === currentQuote),
    "testimonial-card--BDE6EE--active":
      (index === 1) & (danielQuote === currentQuote),
    "testimonial-card--FBFE67--active":
      (index === 2) & (danielQuote === currentQuote),
    "testimonial-card--DAC5FA--active":
      (index === 3) & (danielQuote === currentQuote),
    "testimonial-card--F55B1C--active":
      (index === 4) & (danielQuote === currentQuote),
    "testimonial-card--029C53--active":
      (index === 5) & (danielQuote === currentQuote),
    "testimonial-card--FFB2CE": index === 0,
    "testimonial-card--BDE6EE": index === 1,
    "testimonial-card--FBFE67": index === 2,
    "testimonial-card--DAC5FA": index === 3,
    "testimonial-card--F55B1C": index === 4,
    "testimonial-card--029C53": index === 5,
  });
  return (
    <div
      ref={cardTestRef}
      className={`${cardClasses}`}
      onMouseOver={() => setCurrentQuote(danielQuote)}
    >
      <div className="testimonial-card__svgs">
        <svg
          width="42"
          height="31"
          viewBox="0 0 42 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 30.9801V21.7756C0.5 19.1619 1.01136 16.4915 2.03409 13.7642C3.05682 11.0369 4.40625 8.46591 6.08239 6.05114C7.75852 3.63636 9.5767 1.61932 11.5369 0L19.5483 4.73011C17.9574 7.23011 16.6506 9.84375 15.6278 12.571C14.6335 15.2983 14.1364 18.3381 14.1364 21.6903V30.9801H0.5ZM22.0199 30.9801V21.7756C22.0199 19.1619 22.5312 16.4915 23.554 13.7642C24.5767 11.0369 25.9261 8.46591 27.6023 6.05114C29.2784 3.63636 31.0966 1.61932 33.0568 0L41.0682 4.73011C39.4773 7.23011 38.1705 9.84375 37.1477 12.571C36.1534 15.2983 35.6562 18.3381 35.6562 21.6903V30.9801H22.0199Z"
            fill="var(--primary)"
          />
        </svg>
        <Link to={personalLink} isOutbound>
          {linkedin && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="var(--primary)"
                d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77Z"
              />
            </svg>
          )}
          {personalSite && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="var(--primary)"
                fillRule="evenodd"
                d="M12 4a8 8 0 0 0-6.96 11.947A4.99 4.99 0 0 1 9 14h6a4.99 4.99 0 0 1 3.96 1.947A8 8 0 0 0 12 4Zm7.943 14.076A9.959 9.959 0 0 0 22 12c0-5.523-4.477-10-10-10S2 6.477 2 12a9.958 9.958 0 0 0 2.057 6.076l-.005.018l.355.413A9.98 9.98 0 0 0 12 22a9.947 9.947 0 0 0 5.675-1.765a10.055 10.055 0 0 0 1.918-1.728l.355-.413l-.005-.018ZM12 6a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </Link>
      </div>
      <p>{quote}</p>
      <div className="testimonial-card__bio">
        <p>{name}</p>
        <div className="testimonial-card__bio__roles">
          <p>
            {company}
            {", "}
            {role}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
