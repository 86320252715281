import { useEffect, useContext } from "react";
import ReactContext from "../context/reactContext";
import { gsap } from "gsap";

const useSectionHighlight = (pageRef) => {
  const { activeSection } = useContext(ReactContext);
  useEffect(() => {
    if (pageRef.current) {
      const allSections = pageRef.current.children;

      Array.from(allSections).forEach((section) => {
        const sectionChildren = section.children;
        gsap.to(sectionChildren, {
          opacity: 0.1,
          duration: 0.5,
          ease: "power1.in",
        });
      });
    }
    const currentSectionId = document.getElementById(activeSection);
    if (currentSectionId && currentSectionId.children) {
      gsap.to(currentSectionId.children, {
        opacity: 1,
        duration: 0.5,
        ease: "power1.out",
      });
    }
  }, [pageRef, activeSection]);
};

export default useSectionHighlight;
