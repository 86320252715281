import React, { useState, useContext, useRef, useEffect } from "react";
import useModal from "../../../lib/useModal";
import "./techstack.scss";
import { graphql, useStaticQuery } from "gatsby";
import { gsap } from "gsap";
import { useGesture } from "react-use-gesture";
import classNames from "classnames";
import ToggleSwitch from "../../toggle-switch";
import ReactContext from "../../../context/reactContext";
import { assetArrayToObject } from "../../../lib/graphql-helpers";
import { useCheckScreenSize } from "../../../lib/checkScreenSize";
import useSound from "use-sound";
import TSCustomCursor from "../../custom-cursor/ts-cursor/ts-cursor";
import sound from "../../../../static/boop.mp3";

const TechStack = ({ techStack, iconImages }) => {
  const { openModal } = useModal();
  const { theme, hasSound, activeSection } = useContext(ReactContext);
  const { techFilter } = useContext(ReactContext);
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const [currentTech, setCurrentTech] = useState(null);
  const currentTechInfo = techStack.filter((item) => item.name === currentTech);
  const [cursorVisible, setCursorVisible] = useState(false);
  const cursorRef = useRef(null);
  const lastPosition = useRef({ x: 0, y: 0 }); // NEW: Ref to store the last position

  const isMobile = useCheckScreenSize(1000);
  const [playActive] = useSound(sound, {
    volume: 0.25,
  });

  const containerRef = useRef(null);
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg|png|svg)/" }
          relativeDirectory: { eq: "icons" }
        }
      ) {
        edges {
          node {
            name
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
              fluid {
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  `);

  const {
    allFile: { edges: allImages },
  } = data;

  const image = currentTechInfo[0]?.image;
  const description = currentTechInfo[0]?.description;

  const whichIconImages = assetArrayToObject({ images: allImages });
  const whichImage =
    theme && whichIconImages[image + "Light"]
      ? whichIconImages[image + "Light"]
      : whichIconImages[image];

  const bind = useGesture({
    onMove: ({ xy: [x, y] }) => {
      const container = containerRef.current;
      const icons = container.children;

      for (let i = 0; i < icons.length; i++) {
        const icon = icons[i];
        const rect = icon.getBoundingClientRect();
        const dx = x - (rect.left + rect.width / 2);
        const dy = y - (rect.top + rect.height / 2);
        const distance = Math.sqrt(dx * dx + dy * dy);
        const calculatedScale = Math.pow(
          gsap.utils.mapRange(0, 200, 1.04, 1, distance),
          2
        );
        const scale = Math.max(1, calculatedScale); // ensures scale doesn't go below 1

        gsap.to(icon, {
          scale: scale,
          duration: 0.3,
          ease: "power2.out",
        });
      }
    },

    onHover: ({ hovering }) => {
      if (!hovering) {
        gsap.to(containerRef.current.children, { scale: 1, duration: 0.3 });
      }
    },
  });

  useEffect(() => {
    return () => {
      if (containerRef.current) {
        gsap.to(containerRef.current.children, { scale: 1 });
      }
    };
  }, []);

  useEffect(() => {
    if (!containerRef.current || !containerRef.current.children) return;

    if (activeSection !== "db-tech-stack") {
      gsap.to(containerRef.current, {
        y: 100,
        opacity: 0,
        duration: 0.3,
      });
    }

    gsap.set(containerRef.current.children, {
      y: 20,
      opacity: 0,
      duration: 0.3,
    });

    if (activeSection === "db-tech-stack") {
      gsap.to(containerRef.current, {
        y: 0,
        opacity: 1,
        duration: 0.3,
      });
    }
    const childrenArray = [...containerRef.current.children];
    childrenArray.forEach((item, index) => {
      if (activeSection === "db-tech-stack") {
        gsap.to(item, {
          y: 0,
          ease: "elastic.out(1, 0.3)",
          delay: index * 0.005,
          duration: 0.7,
          opacity: 1,
        });
      }
    });
    return () => {
      if (containerRef.current && containerRef.current.children) {
        gsap.killTweensOf(containerRef.current.children);
        gsap.set(containerRef.current.children, { scale: 1 });
      }
    };
  }, [toggleSwitch, techFilter, activeSection]);

  const itemClasses = classNames("tech-stack__index__item cursor-pointer", {
    "tech-stack__index__item--image": !toggleSwitch,
  });

  const whichTechModalClasses = classNames(
    "tech-stack__which-tech cursor-pointer",
    {
      "tech-stack__which-tech--mobile": isMobile,
    }
  );

  const techStackModalComponent = (
    <div className={whichTechModalClasses}>
      {currentTechInfo && currentTechInfo.length > 0 && (
        <div className="tech-stack__which-tech__inner">
          <div className="tech-stack__which-tech__name">
            {!toggleSwitch && !isMobile && <h2>{currentTech}</h2>}
            {toggleSwitch && !isMobile && (
              <img width={32} height={32} loading="lazy" src={whichImage} />
            )}

            {isMobile && (
              <>
                <h2>{currentTech}</h2>
                <img width={32} height={32} loading="lazy" src={whichImage} />
              </>
            )}
          </div>
          <p>{description}</p>
        </div>
      )}
    </div>
  );

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (cursorRef.current) {
        const cursorHeight = cursorRef.current.offsetHeight || 50;
        const cursorWidth = cursorRef.current.offsetWidth || 50;

        // Calculate the top left position of the CustomCursor
        const xPosition = e.clientX - 10;
        const yPosition = e.clientY - cursorHeight + 100;

        // Set boundaries to ensure the CustomCursor does not go beyond the viewport
        const minXPosition = 0;
        const minYPosition = 0;

        const maxXPosition = window.innerWidth - cursorWidth;
        const maxYPosition = window.innerHeight - cursorHeight;

        const adjustedX = Math.min(
          Math.max(xPosition, minXPosition),
          maxXPosition
        );
        const adjustedY = Math.min(
          Math.max(yPosition, minYPosition),
          maxYPosition
        );

        cursorRef.current.style.top = `${adjustedY}px`;
        cursorRef.current.style.left = `${adjustedX}px`;

        // Store the current adjusted position
        lastPosition.current = { x: adjustedX, y: adjustedY };
      }
    };

    if (cursorVisible && cursorRef.current) {
      // Set the initial position of the custom cursor to the last stored position
      cursorRef.current.style.top = `${lastPosition.current.y}px`;
      cursorRef.current.style.left = `${lastPosition.current.x}px`;

      document.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [cursorVisible]);

  useEffect(() => {
    if (currentTech) {
      const ModalContent = techStackModalComponent;

      if (isMobile) {
        openModal(ModalContent, true);
      }
    }
  }, [currentTech, isMobile]);

  const active = cursorVisible && !isMobile;

  return (
    <div className="tech-stack">
      <div className="tech-stack__inner">
        <div className="tech-stack__index-container">
          <div className="tech-stack__heading">
            <h2>Tech Stack</h2>
            <ToggleSwitch
              setToggleSwitch={setToggleSwitch}
              toggleSwitch={toggleSwitch}
              label="LABEL"
            />
          </div>
          <div
            ref={containerRef}
            className="tech-stack__index cursor-pointer"
            {...bind()}
          >
            {techStack
              .filter((el) =>
                techFilter.type ? el.type.includes(techFilter.type) : true
              )
              .filter((el) =>
                techFilter.tech ? el.tech.includes(techFilter.tech) : true
              )
              .map((item) => {
                const { name, color, image } = item;
                const whichImage =
                  theme && iconImages[image + "Light"]
                    ? image + "Light"
                    : image;
                const techStackItem = !toggleSwitch ? (
                  <img
                    alt={name}
                    width={58}
                    height={62}
                    loading="lazy"
                    src={iconImages[whichImage]}
                  />
                ) : (
                  <p>{name}</p>
                );
                return (
                  <div
                    onMouseEnter={() => {
                      if (hasSound) playActive();
                      setCursorVisible(true);
                      {
                        !isMobile && setCurrentTech(name);
                      }
                    }}
                    onMouseLeave={() => setCursorVisible(false)}
                    key={name}
                    className={itemClasses}
                    onClick={() => {
                      isMobile && setCurrentTech(name);
                    }}
                  >
                    {techStackItem}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {cursorVisible && !isMobile && (
        <TSCustomCursor
          active={active}
          component={techStackModalComponent}
          ref={cursorRef}
        />
      )}
    </div>
  );
};

export default TechStack;
